import img1 from '../../../assets/img/common/feedback1.jpg'
import img2 from '../../../assets/img/common/feedback2.jpg'
import img3 from '../../../assets/img/common/feedback3.jpg'

export const ClientsData = [
    {
        img: img1,
        name: "Tania M.",
        des: "CEO of LTD company",
        para: `Fantastic. These guys were right on time! (even though I changed the time on them!). And great communication. Hope to get them again in the future.`
    },
    {
        img: img2,
        name: "Laurie S.",
        des: "CEO of LTD company",
        para: `Very professional and arrived on time!`
    },
    {
        img: img3,
        name: "Todd L.",
        des: "CEO of LTD company",
        para: `Great helpers, awesome communication and made them look easy. Will definitely use again.`
    },
    {
        img: img1,
        name: "Hannah Q.",
        des: "CEO of LTD company",
        para: `Super friendly and on time! Had a lot of laughs with them! They were respectful and got the job done well and effectively!`
    },
    {
        img: img1,
        name: "Kris A.",
        des: "CEO of LTD company",
        para: `Amazing service. One of the best and fastest movers. Can't recommend enough.`
    },
    {
        img: img1,
        name: "Mark C.",
        des: "CEO of LTD company",
        para: `Prentice and Trae did a spectacular job!`
    }
]
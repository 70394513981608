import React from "react";
// Import ContactForm Area
import ContactForm from "../Contact/ContactForm";
// Import ContactInfo Area
import ContactInfo from "../Contact/ContactInfo";
// Import ContactMap Area
import ContactMap from "../Contact/ContactMap";

//ContactArea Area
const ContactDetails = () => {
  return (
    <>
      <section id="contact_area_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_heading_center">
                <h2>Request a Quote</h2>
              </div><br /><br />
              <div className="contact_form_main">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactDetails;

import React from 'react'
//Import FormInput
import FormInput from '../Common/FormInput'
// ContactForm Area
import emailjs from '@emailjs/browser';

const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_l85jgqp",
        "template_a40pzxs",
        e.target,
        "user_oEFEjNM3NftFo8RPUv57m"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    alert("Thanks for your feedback!");
    e.target.reset();
  };


const ContactForm = () => {
    return (
        <> 
            <form onSubmit={sendEmail}>
                <div className="row">
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name="name"
                            classes={'form-control'}
                            placeholder={'First and Last Name'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name="email"
                            classes={'form-control'}
                            placeholder={'Email'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name="subject"
                            classes={'form-control'}
                            placeholder={'Subject'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <FormInput
                            tag={'textarea'}
                            type={'text'}
                            name="message"
                            classes={'form-control'}
                            placeholder={'Brief description of what youre moving and to where...'}
                        />
                    </div>
                    <div className="col-lg-12">
                        <div className="contact_form_submit">
                            <FormInput
                                tag={'button'}
                                val={'Send'}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ContactForm

export const MenuData = [
    {
        name: <div style={{color: '#d80200'}}>5133930742<i className="fa fa-phone"></i></div>,
        href: "tel:5133930742",
        has_children: false,
    },
    {
        name: <i className="fab fa-instagram" style={{color: '#d80200'}}></i>,
        href: "https://www.instagram.com/atl_transportation?utm_source=qr",
        has_children: false,
    },
    {
        name: <i className="fab fa-facebook-f" style={{color: '#d80200'}}></i>,
        href: "https://www.facebook.com/profile.php?id=100076250116678",
        has_children: false,
        children: [
            {
                name: "Blog Grid",
                href: "/blog_grid",
                has_children: false,
            },
            {
                name: "Blog With Sidebar",
                href: "/blog_with_sidebar",
                has_children: false,
            },
            {
                name: "Single Blog",
                href: "/blog_details",
                has_children: false,
            }
        ]
    }
]
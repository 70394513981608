import img1 from '../../../assets/img/svg/house.svg'
import img2 from '../../../assets/img/svg/crm.svg'
import img3 from '../../../assets/img/svg/clock.svg'

export const OurAdventagesData = [
    [
        {
            img: img2,
            heading: "Experience",
            para: "With over 200+ customers served, experience is key."
        },
        {
            img: img2,
            heading: "Reach",
            para: "Now serving Dayton, Cincinnati, Monroe, Franklin, Indiana and Kentucky."
        },
        {
            img: img2,
            heading: "Versatile",
            para: "From residential homes, apartment condos and commercial business, Above the Line Transportation does it all. "
        }
    ]
]
import React from 'react'
import {Link} from 'react-router-dom';
// Import Logo Import
import logo from '../../assets/img/logo.PNG'

const FooterItems = [
    {
        title: "Quick Link",
        links: [
            {linkTitle: "Home", link: "/"},
            {linkTitle: "About Company", link: "/about"},
            {linkTitle: "Our Services", link: "/service"},
            {linkTitle: "Recent News", link: "/blog_grid"},
            {linkTitle: "Contact us", link: "/contact"},
        ],
    }
]

const Footer = () => {
    return (
        <>
            <footer id="footer_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="footer_wedget">
                                <img src={logo} style={{width: '40%', height: '40%'}} alt="logo_img"/>
                                <p style={{color: 'white'}}>Above the Line Transportation LLC is a point-to-point shipping company focusing on deliveries, furniture, freight and haul away.</p>
                                <div className="footer_social_icon">
                                    <a href="https://www.facebook.com/profile.php?id=100076250116678"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/atl_transportation?utm_source=qr"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="footer_wedget">
                                {FooterItems.slice(0, 1).map((data, index) => (
                                    <FooterWidget data={data} key={index}/>
                                ))}

                            </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-12 col-12">

                            <div className="footer_wedget">
                                {FooterItems.slice(1, 2).map((data, index) => (
                                    <FooterWidget data={data} key={index}/>
                                ))}
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="footer_wedget" style={{textAlign: 'right'}}>
                                <h4>Contact Info</h4>
                                <div className="contact-info-footer">
                                    <ul>
                                        <li><i className="fas fa-map-marker-alt"></i>Cincinnati, Ohio</li>
                                        <li><i className="far fa-envelope"></i> <a
                                            href="mailto:atltransportation513@gmail.com">atltransportation513@gmail.com</a></li>
                                        <li><i className="fas fa-phone-volume"></i> <a href="tel:5133930742"> +513
                                            393 0742</a></li>
                                        <li><i className="far fa-clock"></i>Mon - Sun: 9:00 - 5:00</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </footer>
            
        </>
    )
}

export default Footer

export const FooterWidget = ({data}) => (
    <>
        <h4>{data.title}</h4>
        <ul>
            {data.links.map((datas, index) => (
                <li key={index}><Link to={datas.link}>{datas.linkTitle}</Link></li>
            ))}
        </ul>
    </>
)